import React from 'react'
import {Box} from '../../mui'
import Carousel from '../Dashboard/Carousel'

export default function Home() {
    return (
        <Box mx={2}>
            
                <Carousel/>
        
        
            
          
        </Box>
    )
}
